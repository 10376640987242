:root {
    text-align: right;
    color: black;
    font-family: 'OpenSans-Bold', 'sans-serif';
    font-family: 'FbPractica_Regular', 'sans-serif';
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-family: "FbMetali-Regular", "Helvetica", "Arial", sans-serif;
    /* --SecondFont: "Roboto", "Helvetica", "Arial", sans-serif; */
    /* --MyFont: "Roboto", "Helvetica", "Arial", sans-serif; */
    --HeaderFont: 'FbMetali-Bold', 'sans-serif';
    /* --HeaderFont: 'FbEmeq-Bold', 'sans-serif'; */
    --engFont: 'OpenSans-Bold', 'sans-serif';
    font-size: 15px;
    --MyFont: "FbMetali-Regular";
    --SecondFont: "FbMetali-Regular";
    /* --SecondFont: 'FbEmeq-Bold', 'sans-serif'; */
    --mainYellow: #fec000;
    --mainYellowOpace: #fec00057;
    --mainTurqi: #aeeef1;
    --turqiText: #21ccd4;
    --priceText: #3ec6cd;
    --newMenuTurq: #daf7f9;
    --fotterColor: #2c394a;
    --background-color2: #e9ebee;
    --specialGreen: #41bd31;
    --specialGreen2: #42d390;
    --specialRed: #cc1417;
    --lGray: #d7dce4;
    --veryLightGray: #e8edf5;
    --GrayPlus: #989ca1;
    --smallFont: 70%;
    --mediumFont: 80%;
    --regularFont: 100%;
    --bigFont: 120%;
    --veryBigFont: 140%;
    --huge: 160%;
    --swiper-theme-color: #fec000 !important;
}

.bgLGray {
    background-color: var(--veryLightGray);
}

.MyFont {
    font-family: var(--MyFont);
}

.HeaderFont {
    font-family: var(--HeaderFont);
}

.SecondFont {
    font-family: var(--SecondFont);
    font-size: 155% !important;
}

.SecondFontRegular {
    font-family: var(--SecondFont);
}

.FirstFont {
    font-family: var(--MyFont) !important;
}

.clearBG {
    background-color: unset !important;
}

.firstStrMaster {
    height: 40px;
    padding: 10px;
}

.ContinueToPayWrap {
    height: 50px;
    position: fixed;
    bottom: 15px;
    width: 100%;
    z-index: 850;
}

.ContinueToPayLine {
    height: 45px;
    background-color: var(--mainYellow);
    border-radius: 15px;
    position: relative;
    width: calc(100% - 15px);
    margin: 0 auto;
    overflow: hidden;
}

.contPayLeft {
    float: left;
    width: 80%;
    overflow: hidden;
}

.contPayRight {
    float: right;
    width: 20%;
    overflow: hidden;
}

.shopCartBtnWrap {
    width: 100%;
    height: 100%;
    background-color: var(--mainYellow);
    margin-right: 10px;
    float: right;
}

.Hpage {
    margin-bottom: -35px;
}

.WebPage {
    margin-bottom: 35px;
}

.BottomMast .HPTImgWrap {
    width: 100%;
}

.BottomMastBtn {
    border-radius: 10px;
    direction: rtl;
    width: 90% !important;
    left: 5%;
}

.shopCartBtnWrap svg {
    margin-top: 0px;
    padding: 10px;
}

.shopCartBtnWrap span {
    position: absolute;
    border-radius: 25px;
    color: white;
    background-color: black;
    bottom: 7px;
    right: 38px;
    min-width: 15px;
    font-size: 11px;
    text-align: center;
}

.contToPayText {
    float: right;
    line-height: 50px;
    width: 55%;
    text-align: center;
    direction: rtl;
}

.priceTxt {
    float: left;
    line-height: 50px;
    text-align: center;
    direction: rtl;
    width: 22%;
}

.WebPage {
    direction: rtl;
}

.engFont {
    font-family: var(--engFont);
    font-size: 88%;
}


/********************** Master  *************************/

.scrlBtn {
    color: var(--turqiText);
    transform: rotate(180deg);
    position: fixed;
    bottom: 105px;
    left: 10px;
    font-size: 260% !important;
    opacity: 0;
    z-index: 1000;
    transition: opacity 5s;
    max-width: 50px;
}

.scrolBtnShow {
    opacity: 1;
    -webkit-transition: opacity 5s;
    -moz-transition: opacity 5s;
    -o-transition: opacity 5s;
    transition: opacity 5s;
}

.inMast {
    position: absolute;
    width: 100%;
    z-index: 700;
}

.BottomBannerImages {
    width: 94%;
    margin: 0 auto;
}

.botBannerSwiper .swiper-pagination-bullets,
.botBannerSwiper .swiper-pagination-custom,
.botBannerSwiper .swiper-pagination-fraction {
    bottom: 10px !important;
}

.Fotter {
    width: 100%;
    min-height: 300px;
    /* background-color: var(--fotterColor); */
    padding-bottom: 30px;
}

.Fotter .mobiLogoDiv {
    margin-top: 10px;
    margin-right: 10px;
}

.footerBlok {
    width: calc(50% - 30px);
    float: right;
    text-align: right;
    direction: rtl;
    padding: 15px;
}

.fotterMainHeadLine,
.fotterHeadLine,
.fotterHeadLine,
.fotterHeadLine p {
    color: var(--lGray);
    text-decoration: none;
    display: table;
    direction: rtl;
    text-align: right;
}

.fotterHeadLine a:visited {
    color: var(--lGray);
}

.userLogIcon {
    float: left;
    margin-left: 10px;
    margin-top: 10px;
    width: 35px;
    height: 35px;
}

.userLogIcon img {
    width: 100%;
    height: 100%;
}


/********************** mobiMnu *******************/

.topSlidesWrap {
    width: 80%;
    margin: 0 auto;
    margin-top: 15px;
}

.mobiMnuDiv {
    overflow: hidden;
    text-align: left;
    z-index: 1000;
    position: fixed;
}

.mobiMnu {
    width: 100%;
    border: none;
    border-spacing: unset;
    right: 0;
    position: fixed;
    top: 0;
    bottom: 0;
    padding-bottom: 30px;
    transition: right 0.5s;
    z-index: 600;
    overflow: scroll;
}

.fotterMnu {
    width: 100%;
    border: none;
    border-spacing: unset;
    position: relative;
    padding-bottom: 60px;
    transition: right 0.5s;
    z-index: 600;
    overflow: auto;
}

.posRelative {
    position: relative;
}

.imgWrap {
    width: 100%;
}

.mainRestP {
    width: 100%;
}

.imgDiv {
    padding-top: calc(65% - 80px);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 15px;
}

.CloseMobiBtn {
    text-align: right;
    overflow: hidden;
}

.alRight {
    padding-right: 45px;
}

.mobMnuSh {
    right: 100%;
}

.mobiMnu {
    background-color: white;
}

.mobiMnuInWrap {
    width: 100%;
    margin: 0 auto;
}

.mobiMnu a {
    text-decoration: none;
    color: black;
    font-size: 20px;
    width: 100%;
}

.circleOnMNU {
    width: 0.9rem !important;
    height: 1.1rem !important;
}

.botmMnuLogoWrap {
    direction: rtl;
    text-align: right;
    width: 80%;
    margin: 0 auto;
    margin-top: 30px;
}

.btmLgoImg {
    width: 50%;
}

.botmMnuLogoTxt {
    color: var(--GrayPlus);
}

.mobiMnuTxt {
    font-weight: bold;
}

.userDet {
    overflow: hidden;
    width: 100%;
}

.mobiMnuLnkItm1 {
    line-height: 1.2rem;
    position: relative;
    font-size: 1.2rem;
    width: 100%;
    margin: 0 auto;
    background-color: white;
    /* padding-right: 35px; */
    text-align: right;
    overflow: hidden;
    padding-top: 5px;
    padding-bottom: 5px;
}

.mobiMnuLnkItm2 {
    line-height: 35px;
    border-bottom: 1px solid black;
    position: relative;
    font-size: 120%;
    /* top: -10px; */
    width: 80%;
    margin: 0 auto;
    background-color: white;
    /* padding-right: 35px; */
    text-align: right;
}

.timeTxt .theImg svg {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.mobiMnuLnkItm2:last-child {
    border-bottom: none;
}

.fatLIne {
    padding-top: 10px;
    width: 80%;
    margin: 0 auto;
    border-top: 5px solid black;
    margin-top: 20px;
    margin-bottom: 5px;
}

.fotterMnu .mobiMnuLnkItm2 {
    width: 100% !important;
}

.closeMnuBtn {
    padding-left: 10px;
    width: 30px;
    height: 60px;
    background-color: transparent;
    border-radius: 50%;
    z-index: 801;
    float: left;
}

.closeMnuBtn svg {
    width: 100%;
    height: 100%;
    color: black;
    z-index: 500;
}

.mobiMnuLnkItm td {
    padding-right: 10px;
    font-weight: bold;
    font-size: 115%;
}

.mobiMnuBtnDiv {
    width: 100%;
    float: right;
    /* padding: 15px;*/
}

#mobiMnuBtn {
    float: left;
    width: 20%;
}

.connectGo {
    border: 1px solid var(--lGray);
    display: table;
    padding: 2px 5px;
    border-radius: 5px;
    float: right;
    margin-right: 10px;
}

.mobiFloatLeft {
    color: white;
    position: fixed;
    right: calc(100% - 42px);
    /* width: 18px; */
    padding: 3px;
    line-height: 30px;
    height: 25px;
    background-color: var(--turqiText);
    border-radius: 5px;
    /* border-bottom-right-radius: 5px; */
    overflow: hidden;
    transition: right 0.5s;
    /* box-shadow: 0px 1px 3px 0px #000000; */
    top: 10px;
}

.mobiFloatLeft svg {
    width: 100%;
    height: 100%;
    margin: 0;
}

.mobiFloatLeftUsr {
    color: white;
    position: fixed;
    right: calc(100% - 45px);
    /* width: 18px; */
    padding: 3px;
    /* line-height: 41px; */
    height: 25px;
    background-color: var(--turqiText);
    border-radius: 5px;
    /* border-bottom-right-radius: 5px; */
    overflow: hidden;
    transition: right 0.5s;
    /* box-shadow: 0px 1px 3px 0px #000000; */
    bottom: 70px;
}

.mobiFloatLShow {
    left: 12px;
    transform: rotate(180deg);
    z-index: 1500;
}

.mobiInArrow {
    position: fixed;
    top: 15px;
    right: 0;
    width: 18px;
    line-height: 50px;
    height: 50px;
    background-color: var(--mainTurqi);
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    overflow: hidden;
    transition: right 0.5s;
    box-shadow: 0px 1px 3px 0px #000000;
}

.mobiArr {
    /* width: 18px;
    padding-top: 17px;
    left: -2px; */
    position: relative;
}

.usrDispOnMenu {
    overflow: hidden;
    float: right;
    padding-top: 10px;
}

.inOutOnMenu {
    float: left;
    width: 40px;
}

.inOutLogDiv {
    padding-left: 15px;
}

.inOutLogDiv svg {
    padding-left: 5px;
    width: 20px;
    font-size: 1rem;
}

.inOutTop {
    margin: 0 auto;
}

.inOutBott {
    width: 100%;
    font-size: 12px;
}


/********************** logo *******************/


/* .onlyOnMobile .mobiLogoDiv {
    width: 50px;
    height: 40px;
    top: -5px;
    position: relative;
}

.onlyOnMobile .mobiLogoDiv img {
    width: auto;
    max-height: 40px;
} */

.mobiLogoDiv {
    width: calc(60% - 30px);
    float: right;
}


/********************** Search *******************/

.SearchDiv {
    margin-top: 15px;
    overflow: hidden;
    height: 45px;
    background-color: var(--newMenuTurq);
    border-radius: 10px;
}

.SearchDiv input {
    line-height: 40px;
    border: none;
    padding-right: 5px;
    text-align: right;
    float: right;
    font-size: 90%;
    width: calc(90% - 32px);
    margin-right: 2%;
    background-color: transparent;
}

#searchBtn {
    height: 25px;
    float: right;
    padding: 10px;
    background-color: var(--newMenuTurq);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}


/********************** Home Page *******************/

.favBtn {
    border: 1px solid var(--lGray);
    border-radius: 10px;
    /* direction: rtl; */
    width: 35% !important;
}

.favBtn .pgBigTxt,
.favBtn .pgSmallTxt,
.catsListItem .pgBigTxt,
.catsListItem .pgSmallTxt {
    text-align: right;
    white-space: nowrap;
}

.favBtn .theImg {
    width: 100%;
    /* padding-top: 100%; */
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.favBtn .theImg svg,
.pgSmallTxt .theImgwrap .theImg svg {
    font-size: 0.8rem;
    transform: matrix3d(-1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    padding-top: 0px;
}

.favBtn .theImgwrap {
    width: 10%;
    overflow: hidden;
    float: right;
}

.favBtn a {
    text-decoration: none;
}

.favBtn .imgWrap {
    width: calc(100% - 10px);
    margin: 5px auto;
}

.favBtn .timeTxt {
    float: right;
    padding-right: 5px;
    padding-top: 2px;
    line-height: 20px;
}

.favBtn .imgDiv {
    padding-top: 90%;
}

.favBtn .botBlock {
    bottom: 0px !important;
}

.catsListItem {
    border: 1px solid var(--lGray);
    border-radius: 10px;
    /* direction: rtl; */
    width: calc(50% - 20px) !important;
    float: right;
    margin-right: 10px;
    margin-bottom: 10px;
}

.theTitlePop {
    margin-top: 15px !important;
}

.theTitlePop,
.theTitlePop span {
    font-family: var(--SecondFont) !important;
    line-height: 1.3 !important;
}

.theBottomPop {
    margin-top: 15px !important;
}

.theBottomPop span {
    font-family: var(--MyFont) !important;
    font-size: var(--bigFont);
    line-height: 1 !important;
}

.catsPagelist {
    overflow: hidden;
    top: 70px;
    position: relative;
    padding-bottom: 70px;
}

.catsPagelist .pgBigTxt {
    text-align: center;
    font-size: var(--veryBigFont);
}

.catsPagelist .botBlock {
    min-height: unset;
}

.HPTopImages {
    width: 100%;
}

.HPTImgWrap {
    width: 100%;
    float: left;
}

.HPTopImagesLst {
    position: relative;
}

.smallTopImagesLst {
    position: relative;
}

.smallTopImages {
    width: 60px;
    margin: 0 auto;
}

.smallTopImgWrap {
    width: 50px;
}

.smallTopImage {
    width: 100%;
    object-fit: cover;
}

.smallTopimgTxt {}

.HPTopImage {
    width: 100%;
    object-fit: cover;
}

.HPTopimgTxt {
    text-align: center;
    position: absolute;
    top: 50%;
    width: 80%;
    transform: translate(-15%, -50%);
}

.Hprest {
    display: block;
    overflow: hidden;
    width: 92%;
    margin: 0 auto;
    margin-top: 12px;
    border-radius: 5px;
    position: relative;
}

.Hprest img {
    width: 100%;
}

.imgWrapDivClose {
    border-radius: 10px;
    position: absolute;
    display: grid;
    width: 100%;
    height: 98%;
    background-color: rgba(0, 0, 0, 0.6);
    top: 0;
    z-index: 88;
}

.imgWrapDivCloseSmall {
    height: 100%;
}

.imgWrapDivClose span {
    color: lightgray;
    align-self: center;
    justify-self: center;
}

.botBlock {
    width: 96%;
    margin: 0 auto;
    position: relative;
    background-color: white;
    overflow: hidden;
    bottom: 10px;
    min-height: 65px;
    border-radius: 15px;
}

.botBlock .lSide {
    padding: 2%;
    text-align: right;
    width: 38%;
    margin-top: 3px;
    background-color: white;
}

.botBlock .lSide .theImg {
    width: 100%;
    /* padding-top: 100%; */
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.botBlock .lSide .theImgwrap {
    width: 15%;
    overflow: hidden;
    float: right;
}

.botBlock .lSide .timeTxt {
    float: right;
    padding-right: 5px;
    padding-top: 2px;
}

.botBlock .lSide .pgSmallTxt {
    text-align: right !important;
    margin-right: 10px;
}

.botBlock .rSide .pgSmallTxt,
.botBlock .lSide .pgSmallTxt {
    /* white-space: nowrap; */
    direction: rtl;
}

.botBlock .rSide {
    padding: 2%;
    text-align: right;
    width: 54%;
    margin-top: 3px;
}

.borderLeftLine {
    border-left: 1px solid black;
}

.pgBigTxt {
    padding-top: 5px;
    padding-right: 5px;
    padding-bottom: 5px;
    line-height: 100%;
    text-align: right;
}

.pgSmallTxt {
    padding-top: 5px;
    padding-right: 5px;
    padding-bottom: 5px;
    line-height: 100%;
    text-align: right;
    overflow: hidden;
}

.pgOpenCloseBlk {
    padding: 5px 10px 2px 10px;
    line-height: 100%;
    color: black;
    display: table;
    border-radius: 5px;
    float: right;
    margin-block-end: 0em;
    border: 1px solid var(--GrayPlus)
}

.pgOpenCloseBlk .specDot .css-i4bv87-MuiSvgIcon-root,
.botBlock .specDot .css-i4bv87-MuiSvgIcon-root {
    height: unset
}

.thumsUp {
    width: calc(10% - 10px);
    padding: 5px;
    float: right;
    line-height: 100%;
}

.orderPlace {
    width: calc(40% - 15px);
    padding: 5px;
    float: right;
    line-height: 100%;
    text-align: center;
}

.lkNumb {
    font-size: 12px;
}

.css-ci5apu-MuiSvgIcon-root {
    color: black !important;
}

.regularPage {
    top: 80px;
    position: relative;
    width: 80%;
    margin: 0 auto;
    padding-bottom: 80px;
    direction: rtl;
    min-height: 500px;
}

.inPage {
    top: 80px;
    position: relative;
    width: 95%;
    margin: 0 auto;
    padding-bottom: 80px;
    direction: rtl;
    min-height: 500px;
}

.CateguryPage {
    position: relative;
    width: 100%;
    margin: 0 auto;
    /* padding-bottom: 80px; */
    direction: rtl;
    min-height: 500px;
    overflow: hidden;
}

.CateguryPage .pagHead {
    padding-bottom: 20px;
}

.catText {
    overflow: hidden;
    padding-right: 2%;
    padding-left: 2%;
    transition: height 1s;
    /* margin-top: 15px; */
    position: relative;
    -webkit-transition: height 1s;
    -moz-transition: height 1s;
    -o-transition: height 1s;
    transition: height 1s;
    text-align: center;
}

.categuryTxt {
    height: 0px;
}

.chargedMsg {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: fixed;
    z-index: 7000;
    background-color: rgba(0, 0, 0, 0.3);
}

.innerChargMsg {
    margin: 50px auto;
    width: 90%;
    max-width: 400px;
    overflow: hidden;
    border-radius: 15px;
    background-color: white;
    min-height: 150px;
    height: auto;
    padding: 2%;
    position: relative;
}

.prmsDiv {
    overflow: hidden;
}

.bottomBtn {
    background-color: var(--mainYellow) !important;
    font-weight: bold;
    position: absolute;
    bottom: 0;
    margin: 0 auto;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    width: 100%;
    text-align: center;
    line-height: 50px;
    border: none;
    font-weight: bold;
    font-size: 30px;
    color: black;
    left: 0%;
}

.pagHead {
    padding-right: 20px;
}

.TheOrderList .takeAwayBlock {
    overflow: hidden;
    margin-top: 15px;
    margin-bottom: 15px;
}

.TheOrderList .theImg {
    float: right;
    width: 20px;
    height: 50px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;
    margin-left: 15px;
}

.TheOrderList .txtOrdType {
    max-width: 100%;
}

.takeAwayBlock .addressLine span {
    line-height: unset !important;
}


/*************************** The order List ******************************/

.noItmes {
    top: 100px;
    position: relative;
    margin: 0 auto;
    width: 80%;
    min-height: 400px;
}

.smallIconWrap {
    width: 15px;
    height: 15px;
    float: right;
}

.smallIcon {
    width: 100%;
    height: 100%;
}

.addressBox {
    position: fixed;
    top: 0px;
    background-color: rgba(0, 0, 0, 0.5);
    bottom: 0px;
    right: 0;
    left: 0;
    border: 1px solid;
}

.sideClosBtn {
    top: 5px;
    width: 20px;
    float: right;
    margin-left: 10px;
}

.sideClosBtn svg {
    width: 100%;
}

.addressBox #SelectAdd {
    height: 85%;
    position: relative;
    top: 50px;
    overflow: auto;
    background-color: #fff;
    padding-top: 10px;
    width: 96%;
    margin: 0 auto;
    border-radius: 15px;
}

.TheOrderList {
    position: relative;
    margin: 0 auto;
    padding-bottom: 80px;
    direction: rtl;
    min-height: 500px;
    z-index: 800;
}

.TheOrderListToDisp {
    position: relative;
    margin: 0 auto;
    padding-bottom: 20px;
    direction: rtl;
    z-index: 800;
}

.ordLstSpesBlk {
    padding-right: 10px;
    overflow: hidden;
    padding-top: 15px;
    border-bottom: 1px solid var(--lGray);
}

.ordLstSpesBlk .lSideSmall {
    text-align: left;
}

.spaceAndLine {
    border-bottom: 1px solid var(--lGray);
    padding-top: 15px;
    padding-bottom: 15px;
}

.ordLstSpesBlk .fullRow {
    padding-right: 8px;
}

.rSideMiddle .sucItem {
    padding-right: 5px;
    border: none;
}

.sucItem {
    border: none;
    overflow: hidden;
    padding-right: 20px;
}

.TheOrderListIn {
    margin: 0 15px;
}

.firstStrMasterIn {
    width: 80%;
    margin: 0 2%;
    padding-top: 20px;
}

.ordItem {
    border-bottom: 1px solid var(--lGray);
    padding-top: 10px;
    overflow: hidden;
}

.startNBlock {
    margin-top: 20px;
}

.rSideFirst {
    width: 10%;
    float: right;
    min-height: 1px;
}

.rSideFirst2 {
    width: 70%;
    float: right;
}

.rSideMiddle {
    width: 70%;
    float: right;
}

.rSideBig {
    width: 80%;
    float: right;
    line-height: 25px;
}

.lSideSmall {
    width: 20%;
    float: left;
}

.rSideMedBig {
    width: 59%;
    float: right;
    line-height: 25px;
}

.lSideMedSmall {
    width: 40%;
    float: left;
}

.notForDelivery {
    display: block;
    text-align: right;
    font-size: 0.9em;
    font-weight: bold;
    margin-top: 10px;
}

.ordItmOptHead {
    width: 90%;
    margin-right: 10%;
}

.chkBxChange {
    padding: unset !important;
}

.ordItmLine {
    overflow: hidden;
    line-height: 20px;
}

.firstLn {
    overflow: hidden;
}

.firstLn .lSideSmall {
    text-align: left;
    direction: revert;
    line-height: 20px;
}

.ordItmLine .lSideSmall {
    text-align: left;
    direction: ltr;
}

.slideMeUp {
    height: 0px !important;
    padding: 0px !important;
    -webkit-transition: height 1s, padding 1s;
    -moz-transition: height 1s, padding 1s;
    -o-transition: height 1s, padding 1s;
    transition: height 1s, padding 1s;
}

.itemMount {
    height: 32px;
    padding: 0;
}

.TheMoreThenOneMount {
    height: 32px;
    padding: 0;
    /* position: absolute; */
    /* left: 70px; */
    float: left;
}

.itemRemraks {
    height: 120px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.ButtonMe {
    margin-left: 5%;
    margin-top: 5px;
    /* padding: 3px; */
    /* background-color: var(--mainTurqi); */
    width: 25%;
    text-align: center;
    font-size: 95%;
    text-decoration: underline;
    text-align: center;
    margin-bottom: 5px;
    margin: 0 auto;
    padding-top: 5px;
    padding-bottom: 5px;
}

.ButtonMe2 {
    margin-left: 5%;
    margin-top: 5px;
    /* padding: 3px; */
    /* background-color: var(--mainTurqi); */
    width: 20%;
    text-align: center;
    font-size: 100%;
    background-color: var(--mainTurqi);
    color: black;
    text-decoration: none;
    text-align: center;
    margin-bottom: 5px;
    margin: 0 auto;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    border: 1px solid lightgrey;
    box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.2), 4px 2px 13px 0 rgba(0, 0, 0, 0.19);
}

.catTextBtnImg {
    margin: 0 auto;
    width: 40px;
    height: 40px;
}

.catTextBtnImg img {
    width: 100%;
    border-radius: 50%;
}

.page_404 {
    min-height: 500px;
    padding-top: 150px;
    text-align: center;
    width: 80%;
    margin: 0 auto;
}

.imgOn404 {
    padding-top: 50%;
    background-position: center bottom;
    background-size: cover;
}

.page_404 p {
    font-weight: bold;
    font-size: 50px;
    text-align: center;
    font-family: var(--SecondFont);
}

.page_404 p.big {
    font-size: 70px;
}

.page_404 a {
    font-weight: normal;
    font-size: 25px;
    color: white !important;
    text-decoration: underline;
    text-align: center;
    margin: 0 auto;
    border-radius: 10px;
    padding: 5px 20px;
    text-decoration: none;
    background-color: var(--turqiText);
    font-family: var(--SecondFont);
}

.itemMount,
.itemRemraks {
    -webkit-transition: height 1s, padding 1s;
    -moz-transition: height 1s, padding 1s;
    -o-transition: height 1s, padding 1s;
    transition: height 1s, padding 1s;
}

.itemMount .prodPlusMinus {
    width: 70%;
    overflow: hidden;
}

.remarksLine {
    margin-top: 15px;
}

.ordItemMount {
    text-align: center;
    vertical-align: top;
}

.mainMount {
    font-size: 25px;
}

.itemMountRI {
    margin-right: 10%;
    line-height: 32px;
}

.totalTxt {
    margin-top: 10px;
}

.totalTxtR {
    width: 60%;
    margin-right: 10%;
    float: right;
}

.totalTxtL {
    text-align: left;
    float: right;
}

.remarksTxt {
    width: 85%;
    margin-right: 10%;
    border-radius: 5px;
    min-height: 80px;
}

.mediumFont {
    font-size: var(--mediumFont);
}

.getToKnowTxt {
    font-size: var(--mediumFont);
    color: var(--GrayPlus);
    text-align: center;
    padding-bottom: 5px;
}

.getToKnowBtn {
    height: 45px;
    line-height: 45px;
    background-color: var(--mainYellow);
    border-radius: 15px;
    position: relative;
    width: calc(100% - 15px);
    margin: 0 auto;
}

.backToRestMenu {
    height: 30px;
    line-height: 30px;
    padding: 0px 5px;
    background-color: var(--turqiText);
    border-radius: 15px;
    position: relative;
    width: calc(60% - 15px);
    margin: 0 auto;
    float: left;
    width: 65px;
}

.backToRestMenu svg {
    float: left;
    margin-top: 3px;
    color: white;
}

.backToRestMenu span {
    font-size: 12px;
    text-align: right;
    color: white;
    margin: 0 auto;
}

.goKoponBtn {
    height: 35px;
    line-height: 35px;
    margin-right: 5px;
    padding: 0px 5px;
    background-color: var(--turqiText);
    border-radius: 5px;
    position: relative;
    width: calc(60% - 15px);
    /* margin: 0 auto; */
    float: left;
    width: 65px;
}

.goKoponBtn svg {
    float: left;
    margin-top: 3px;
    color: white;
}

.goKoponBtn span {
    font-size: 15px;
    text-align: right;
    color: white;
    margin: 0 auto;
}

.roundedInputTxt {
    border-radius: 5px;
    font-size: 1.2rem;
    padding: 5px;
}

#kopoInput {
    width: 60%;
}

.smal {
    max-width: 400px;
}


/*************************** rest  ******************************/

.restP {
    position: relative;
}

.moreDetailsBlock {
    position: fixed;
    top: 0;
    bottom: 0;
    overflow: hidden;
    background-color: white;
    z-index: 800;
}

.restPHeads {
    text-align: center;
}

.imgRestDiv {
    padding-top: 75%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.comeFromLeft {
    left: 0;
    transition: right 0.5s, left 0.5s;
    position: fixed;
    top: 0;
    background-color: white;
    width: 100%;
}

.shopCrt .TheOrderList {
    position: fixed;
    top: 0;
    background-color: white;
    width: 96%;
    margin: 0 auto;
}

.goFromLeft {
    left: 100%;
    transition: right 0.5s, left 0.5s;
    position: fixed;
    top: 0;
    background-color: white;
    width: 100%;
}

.hideOnLeft {
    right: 100%;
    left: -100%;
    -webkit-transition: right 0.5s, left 0.5s;
    -moz-transition: right 0.5s, left 0.5s;
    -o-transition: right 0.5s, left 0.5s;
    transition: right 0.5s, left 0.5s;
}

.showOnLeft {
    right: 0%;
    left: 0;
    -webkit-transition: right 0.5s, left 0.5s;
    -moz-transition: right 0.5s, left 0.5s;
    -o-transition: right 0.5s, left 0.5s;
    transition: right 0.5s, left 0.5s;
}

.hideOnRight {
    right: 100%;
    left: 100%;
    -webkit-transition: right 0.5s, left 0.5s;
    -moz-transition: right 0.5s, left 0.5s;
    -o-transition: right 0.5s, left 0.5s;
    transition: right 0.5s, left 0.5s;
}

.showOnRight {
    right: 0%;
    left: 0;
    -webkit-transition: right 0.5s, left 0.5s;
    -moz-transition: right 0.5s, left 0.5s;
    -o-transition: right 0.5s, left 0.5s;
    transition: right 0.5s, left 0.5s;
}

.restWrap {
    background-color: white;
}

.restDetailsBlk {
    padding-top: 5%;
    padding-right: 5%;
    padding-left: 5%;
    padding-bottom: 4%;
    overflow: auto;
    margin-top: 5px;
    border-top-right-radius: 15%;
    border-top-left-radius: 15%;
    top: -35px;
    position: relative;
    background-color: white;
    border-bottom: 1px solid black;
    z-index: 89;
}

.margeDownDetails {
    margin-bottom: 20px;
    overflow: hidden;
}

.rSide {
    width: 50%;
    float: right;
}

.lSide {
    width: 50%;
    float: left;
}

.phoneLine {
    font-size: 110%;
    color: black;
    margin: unset;
}

.restSubHeader {
    color: black;
    margin: unset;
    font-weight: normal;
}

.restShortText {
    overflow: hidden;
    margin-bottom: 15px;
}

.restShortText p,
.restShortText span,
.restShortText div,
.restShortText bold,
.restShortText a,
.restShortText p {
    font-size: 100%;
}

.restShortText img {
    object-fit: contain;
    max-width: 100%;
}

.detailsB {
    margin-bottom: 10px;
}

.detailsDiv {
    width: 100%;
    position: relative;
    overflow: hidden;
    position: relative;
    background-color: white;
    z-index: 500;
}

.Hpage .detailsDiv {
    min-height: 200px;
}

.opDot .specDot {
    line-height: 5px;
}

.openHToDay,
.addressLine,
.rating,
.openHToDayName,
.opDot {
    width: 100%;
    overflow: hidden;
    float: right;
}

.openHToDay span,
.addressLine span,
.rating span {
    /* width: calc(75% - 10px); */
    padding-left: 10px;
    float: right;
    line-height: 24px;
}

.openHToDay div svg,
.addressLine div svg,
.rating div svg {
    width: 15px;
    padding-left: 10px;
    float: right;
}

.addressLine div {
    display: block;
    overflow: hidden;
    float: right;
}

.openHToDayName {
    direction: rtl;
    line-height: 24px;
}

.openHToDayName span {
    padding-left: 10px;
    float: right;
    color: black;
}

.specDot {
    float: right;
    width: 10px;
    color: var(--specialGreen);
    margin-left: 5px;
    margin-top: 3px;
}

.specDot svg {
    width: 100%;
    height: auto;
}

.pgSmallTxt .specDot,
.botBlock .specDot {
    margin-top: 0px;
}

.specDotClose {
    color: var(--specialRed);
}

.rating div {
    line-height: 24px;
}

.rating span label {
    font-size: 0.9rem;
    line-height: 24px;
}

.phoneLine,
.moreDetBtn {
    text-align: left;
    line-height: 24px;
}

.clickPhoneBtn {
    background-color: white;
    color: black;
    font-weight: bold;
}

.kosherLine {
    padding-bottom: 20px;
}

.divHead {
    margin-top: 15px;
    padding-right: 15px;
    margin-bottom: 5px;
}

.mnuDivs {
    /* background-color: var(--background-color2); */
    overflow: hidden;
}

.tabClass {
    border-radius: 5px !important;
    font-weight: normal !important;
    z-index: 500;
}

.serachOnMnu {
    text-align: center;
    width: 94%;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    padding: 2px;
    background-color: var(--lGray);
    border-radius: 5px;
}

.clearSrch {
    position: absolute;
    right: 3px !important;
    z-index: 999;
    font-size: 15px !important;
    top: 11px;
}

.serachOnMnu input {
    width: calc(99% - 20px);
    text-align: right;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    float: right;
    border: none;
    background-color: var(--lGray);
    line-height: 32px;
    padding-right: 20px;
    position: relative;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.serachOnMnu #searchBtn {
    height: 15px;
    line-height: 32px;
    background-color: var(--lGray);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    position: absolute;
    top: 0px;
    left: 5px;
}


/*************************** Product Item  ******************************/

.productDiv {
    background-color: white;
    padding-right: 8px;
    overflow: hidden;
    padding-top: 10px;
    padding-left: 12px;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--background-color2);
}

.mnuDivPrdcbold {
    width: 100%;
    font-size: 0.9em;
    font-weight: bold;
}


/* 
.pricebld {
    color: var(--priceText);
} */

.priceln {
    color: var(--priceText);
    width: 100%;
    font-size: 100%;
    letter-spacing: 0.7px;
    padding-top: 5px;
    bottom: 0px;
    font-weight: normal;
    position: relative;
}

.prodDetailsShort {
    position: relative;
    margin-top: 3px;
    display: table;
}

.mnuDivPrdcReg {
    font-size: 0.8em;
    line-height: 120%;
}

.bgImg {
    background-size: cover;
    width: 100%;
    padding-top: 70%;
}

.prodImage {
    width: 100%;
    height: 100%;
    top: 5px;
    position: relative;
    border-radius: 8px;
}

.prodDetaiils {
    width: 69%;
    float: right;
}

.RestMnu .MuiTabs-indicator {
    background-color: var(--mainTurqi) !important;
    height: 45%;
    z-index: 100;
    bottom: 25%;
    border-radius: 10px;
}

.prodcloseBtn img {
    /* display: table; */
    position: absolute;
    z-index: 102;
    /* background-color: white; */
    border-radius: 50%;
    width: 30px;
    height: 30px;
    top: 15px;
    left: 10px;
}


/* .onOrdItm {
    padding-bottom: 15px;
    padding-top: 15px;
    border-bottom: 1px solid;
} */

.smallbtnOrder {
    border: 1px solid;
    border-radius: 8px;
    padding: 3px 5px 3px 5px;
    margin-top: 5px;
    margin-right: 7px;
    z-index: 840;
    position: relative;
    font-family: var(--SecondFont);
}

.css-heg063-MuiTabs-flexContainer button {
    padding-right: 10px !important;
    padding-left: 10px !important;
    margin-right: 5px;
    margin-left: 5px;
    z-index: 101;
    font-family: var(--SecondFont);
}

.noFix {
    position: relative;
}

.fixPos {
    position: fixed;
    width: 100%;
    background-color: white;
}

.fxPosWrap {
    margin: 0 auto;
    width: calc(100% - 16px);
}

.tabsHeadPlace {
    top: 0;
    z-index: 500;
}

.headOnFix {
    text-align: center;
    background-color: white;
    padding-top: 10px;
    padding-bottom: 3px;
}

.noPadd {
    padding-top: 0;
}

.withPadd {
    padding-top: 45px;
}


/********************** CatsOnHomPage *******************/

.detailsLine {
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    /* top: -35px; */
    position: relative;
    background-color: white;
    z-index: 500;
}

.firstMastSec {
    padding-right: 3%;
    padding-left: 3%;
    padding-bottom: 4%;
    overflow: auto;
    margin-top: 5px;
}

.mastSec {
    overflow: hidden;
}

.FourMainLnksDiv {
    width: 94%;
    margin: 0 auto;
    margin-top: 15px;
}

.fourMastBtn {
    background-color: white;
    width: 94%;
    text-align: center;
    line-height: 30px;
    font-size: 75%;
    font-weight: bold;
    white-space: nowrap;
    text-align: center;
    color: black;
    margin: 0 auto;
    border-radius: 10px;
}

.catMasterBtn {
    border: 1px solid var(--lGray);
    border-radius: 10px;
    width: 28% !important;
}

.catMasterBtn a {
    text-decoration: none;
}

.catBtnMasterWrap {
    text-align: center;
    width: 100%;
}

.catBtnMasterWrap .prodImage {
    width: calc(100% - 10px);
    height: 90px;
    top: 5px;
    position: relative;
    border-radius: 15px;
    object-fit: cover;
}

.mastSecCats {
    top: -25px;
    position: relative;
}

.prodImageWrapSmall {
    width: 30%;
    float: left;
    -webkit-transition: width 0.5s;
    -moz-transition: width 0.5s;
    -o-transition: width 0.5s;
    transition: width 0.5s;
}

.prodImageWrapBig {
    width: 100%;
    -webkit-transition: width 0.5s;
    -moz-transition: width 0.5s;
    -o-transition: width 0.5s;
    transition: width 0.5s;
    position: relative;
}

.proBig .prodImageWrap {
    width: 100%;
    float: left;
}

.prodDetails {
    overflow: hidden;
    padding-top: 5px;
    padding-right: 10px;
}

.proBig .prodDetails {
    width: 96%;
}

.prodSText {
    max-height: 35px;
    overflow: hidden;
    line-height: 17px;
    position: relative;
    width: 100%;
    float: right;
}

.inProdTxt {
    width: 80%;
    float: right;
    min-height: 35px;
}

.dotesL {
    position: absolute;
    bottom: 0;
    left: 30px;
    letter-spacing: 1px;
    padding-right: 2px;
    z-index: 400;
    background-color: white;
}

.closeProdBtn {
    width: 25px;
    height: 25px;
    position: absolute;
    z-index: 555;
    margin-top: 8px;
    margin-left: 8px;
    border-radius: 50%;
}

.prodOrderLine {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-top: 7px;
    padding-bottom: 7px;
    border-bottom: 1px solid var(--background-color2);
    font-size: 90%;
}

.nowrap {
    white-space: nowrap;
}


/* 
.pricebld {
    letter-spacing: 1.2px;
    padding-top: 10px;
} */


/* 
.mountLn {
  font-size: 10px;
  text-align: center;
} */

.addToOrderBtn {
    float: left;
    /* margin-left: 5%; */
    margin-top: 5px;
    border-radius: 5px;
    padding: 5px;
    background-color: var(--mainTurqi);
    width: 25%;
    text-align: center;
}

.saucAdPrice {
    float: left;
    padding-top: 13px;
    padding-right: 5px;
}

.spacPrc {
    width: 29px;
    float: left;
    height: 1px;
}

.TmChkSaucess {
    overflow: hidden;
}

.TmChkSaucess .TheMoreThenOneMount {
    float: left;
}

.TmChkSaucess .prodPlusMinus {
    width: unset;
}

.disAbl {
    background-color: var(--lGray);
}

.prodPlusMinus {
    float: left;
    /* width: 25%; */
    margin-top: 0.3rem;
    min-height: 20px;
    margin-left: 3px;
}

.lSideSmall .prodPlusMinus {
    margin-left: 0px;
}

.prodPlusMinusOnRight {
    float: right;
    margin-right: 26px;
    /* border: 1px solid; */
    padding: 3px;
    border-radius: 5px;
    width: 100%;
}

.prodPlusMinus:hover {
    cursor: pointer;
}

.mountNum {
    float: right;
    padding-right: 10px;
}

.plusBtn,
.minusBtn {
    float: right;
    padding-right: 8px;
    padding-left: 8px;
    border: 1px solid var(--background-color2);
    border-radius: 3px;
}

.minusBtn {
    margin-right: 10px;
}

.prodLineHead {
    line-height: 28px;
    float: right;
}

.plusInLHead {
    font-size: 150%;
}

.prodOrderLine:last-child {
    border: none;
}

.rdbsLst fieldset {
    display: block;
    width: 100%;
}

.selBlk {
    direction: rtl;
    margin-right: 0px;
}

.changePrice {
    font-size: 0.8rem;
    float: left;
    /* font-size: 19px; */
    line-height: 32px;
    direction: rtl;
    min-width: 35px;
    min-height: 20px;
}

.prodOptionsSelect {
    position: fixed;
    overflow: auto;
    z-index: 6003;
    background-color: white;
    width: 100%;
    top: 0;
    bottom: -20px;
    left: 0;
    right: 0;
}

.OptionsSelectTheTops {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 55px;
}

.OptionsSelectWrap {
    padding-bottom: 15px;
}

.optSelHeadB {
    text-align: center;
    font-size: 150%;
    font-weight: bold;
    padding-top: 15px;
}

.optSelHeads {
    text-align: right;
    padding-top: 20px;
    padding-bottom: 10px;
    font-size: 132%;
    font-weight: bold;
    margin-left: -11px;
    margin-right: 29px;
    font-family: var(--SecondFont);
}

.prodSelect {
    border-bottom: 1px solid var(--lGray);
    padding-bottom: 15px;
    overflow: hidden;
}

.optionsBtnWrap {
    display: table;
    margin: 0 auto;
    /* position: fixed; */
    bottom: 0;
    width: 100%;
    z-index: 1000;
    background-color: white;
    padding-top: 10px;
    padding-bottom: 50px;
}

.centerDiv {
    display: table;
    margin: 0 auto;
}

.selectOpBtnWrap {
    /* float: left; */
    margin-top: 5px;
    min-width: 100px;
    text-align: center;
    color: white;
}

.selectOpBtnWrap:hover {
    cursor: pointer;
}

.selectOpBtn {
    background-color: var(--turqiText);
    border-radius: 5px;
    margin: 2.5%;
    padding: 10px;
}

.selectOpBtnSmall {
    margin: 0 auto 40px auto;
    width: 50%;
}


/*************************** More Details   OrderTypeSelect******************************/

.selBlk .css-j204z7-MuiFormControlLabel-root {
    margin-right: unset !important;
}

.imgblok {
    background-position: center;
    background-size: 80% 100%;
    background-repeat: no-repeat;
    padding-top: 65%;
    width: 100%;
    margin: 0 auto;
}


/* 
.imgblok img {
    object-fit: cover;
    width: 100%;
    height: 100%;
} */

.OrderTypeSelectBlock .popUpCloseBtn {
    right: unset;
    left: 15px;
}

.moreDetailsBlock,
.OrderTypeSelectBlock {
    /* position: fixed; */
    top: 0;
    bottom: 0;
    height: auto;
    padding-bottom: 20px;
    overflow: auto;
    background-color: white;
    direction: rtl;
    color: black;
}

.moreDetailsBlock {
    z-index: 999;
}

.OrderTypeSelectBlock {
    z-index: 700;
}

.closeSideBtn {
    display: none;
    position: fixed;
    bottom: 50%;
    width: 50px;
    left: 20px;
    height: 50px;
    background-color: white;
    border-radius: 50%;
    z-index: 801;
}

.closeSideBtn svg {
    width: 120%;
    height: 120%;
    transform: rotate(90deg);
    color: var(--mainYellow);
    z-index: 500;
    left: 5px;
    top: -5px;
    position: relative;
}

.TmChk {
    margin: unset;
}

.headOnFixPop {
    text-align: center;
    background-color: #daf7f9;
    width: 100%;
    padding: 0 !important;
    /* position: fixed; */
    z-index: 850;
    border-bottom: 1px dotted var(--lGray);
}

.headOnFixC {
    text-align: center;
    background-color: #daf7f9;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    position: fixed;
    min-height: 27px;
    z-index: 850;
    border-bottom: 1px dotted var(--lGray);
}

.lineHSmall {
    line-height: 85%;
}

.lineDotted {
    border-bottom: 1px dotted var(--lGray);
}

.headOnFixC .inProdTxt {
    min-height: unset;
    float: unset;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    height: 15px;
    -webkit-transition: height 1s;
    -moz-transition: height 1s;
    -o-transition: height 1s;
    transition: height 1s;
}

.headOnFixC .inProdTxt.rollDown {
    height: auto;
    overflow: unset;
    -webkit-transition: height 1s;
    -moz-transition: height 1s;
    -o-transition: height 1s;
    transition: height 1s;
}

.headOnFixC .prodSText {
    font-size: 50%;
}

.secondry_headOnFixC {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    position: fixed;
    z-index: 850;
    border-bottom: 1px dotted var(--mainTurqi);
    background-color: var(--background-color2);
}

.secondry_headOnFixC.big {
    font-size: 250% !important;
}

.backSd {
    width: 30px;
    height: 30px;
    padding-right: 15px;
    border-radius: 50%;
    z-index: 801;
    float: right;
    position: absolute;
    top: 15px;
}

.backSd svg {
    width: 130%;
    height: 130%;
    transform: rotate(0deg);
    color: var(--turqiText);
    z-index: 500;
    left: 5px;
    top: -10px;
    position: relative;
}

.backSdOnlyBtn {
    width: 26px;
    height: 25px;
    right: 12px;
    background-color: white;
    border-radius: 50%;
    z-index: 800;
    float: right;
    position: fixed;
    top: 10px;
}

.backSdOnlyBtn svg {
    width: 130%;
    height: 130%;
    transform: rotate(0deg);
    color: var(--turqiText);
    z-index: 500;
    left: 15%;
    top: -15%;
    position: relative;
}

.restMoreDetails {
    padding: 2% 5%;
    margin-top: 20px;
    direction: rtl;
}

.restMoreDetails a {
    text-decoration: none;
    color: black
}

.pickOrDel {
    font-size: var(--bigFont);
}

.ORDTHead {
    margin: 2%;
    padding: 3%;
    margin-top: 5%;
}

.ORDTYLine {
    padding-top: 10px;
    padding-right: 3%;
    margin: 7% 0%;
    padding-bottom: 20px;
    direction: rtl;
    border: 1px solid #aeeef14f;
    border-radius: 20px;
    overflow: hidden;
    border: 1px solid var(--lGray);
}

.ORDTYLine .theImg {
    float: right;
    width: 50px;
    height: 50px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;
    margin-left: 15px;
}

.txtOrdType {
    float: right;
    direction: rtl;
    font-family: var(--MyFont);
    width: 65%;
    line-height: 1.2;
}

.txtOrdType div {
    padding-bottom: 5px;
}

.ORDTYLine .txtOrdType .big {
    font-size: 190%;
    font-weight: normal;
}

.ORDTYLine .theImg .css-i4bv87-MuiSvgIcon-root {
    width: 100%;
    height: 100%;
}

.goOrdType {
    float: left;
    height: auto;
    top: 25%;
    position: sticky;
    left: 10px;
}

.sPleft {
    float: left;
    color: var(--turqiText);
    font-size: 120%;
}

.sPright {
    float: right;
    font-size: 120%;
}

.contacLine {
    width: 100%;
    position: relative;
    overflow: hidden;
    line-height: 300%;
}

.contacLine {
    border-bottom: 1px solid black;
}

.contacLine:last-child {
    border-bottom: unset;
}

.spaceme15 {
    padding-bottom: 15px;
}

.wazeImg {
    width: 45px;
    height: 45px;
    padding-left: 5px;
    float: left;
}

.whstUpWrap {
    display: block;
    position: relative;
    text-align: center;
}

.whstUpImg {
    width: 45px;
    height: 45px;
    /* padding-left: 5px;
    float: left; */
}

.hideOrds {
    right: 100%;
    opacity: 0;
}

.showOrds {
    right: 0;
    opacity: 1;
    -webkit-transition: opacity 0.5s;
    -moz-transition: opacity 0.5s;
    -o-transition: opacity 0.5s;
    transition: opacity 0.5s;
}


/********************   loginPage   ******************************/

.connBtnItm {
    width: calc(40% - 10px);
    margin: 0 auto;
    line-height: 30px;
    border-radius: 10px;
    font-weight: bold;
    padding-right: 10px;
    overflow: hidden;
    text-align: center;
    background-color: var(--mainYellow);
    border: none;
    color: black;
}

.connActi {
    width: 90%;
}

.connectBox .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    width: 85%;
    margin: unset;
}

.connectBtn {
    width: calc(100% - 10px);
    margin: 0 auto;
    line-height: 45px;
    border-radius: 15px;
    font-weight: bold;
    padding-right: 10px;
    overflow: hidden;
}

.connectBtn span {
    text-align: center;
}

.conClear {
    color: black;
    background-color: var(--mainYellow);
}

.conClear2 {
    color: black;
    background-color: var(--mainYellowOpace);
}

.openAccountBtn {
    width: calc(100% - 10px);
    margin: 0 auto;
    line-height: 45px;
    border-radius: 30px;
    font-weight: bold;
    padding-right: 10px;
    overflow: hidden;
}

.loginBtn {
    top: 4px;
    width: 30px;
    height: 30px;
    float: right;
    background-color: transparent;
    position: relative;
    padding: 3px;
    margin-left: 10px;
    border-radius: 5px;
}

.loginBtn img {
    width: 80%;
    margin: 0 auto;
    position: relative;
    right: 10%;
}

.conAsh {
    background-color: var(--priceText);
    color: black;
}

.gogleMp {
    background-color: white;
    margin-bottom: 15px;
}

.smsColor {
    color: var(--mainYellow);
}

.insideFrame {
    padding: 20px;
}


/********************   UserDetails   ******************************/

.textSizeDiag,
.popFullComp {
    width: 98%;
    min-height: 250px;
    margin: auto;
    direction: rtl;
    text-align: center;
}

.popFullComp .headOnFixC {
    position: relative !important;
    height: 22px;
    line-height: 24px;
    padding: 5px;
}

.popFullComp .headOnFixC .backSd {
    top: 12px;
}

.popFullComp .MuiPaper-root {
    width: 100%;
    margin: 2px;
    max-height: unset;
}

.specialPopComp {
    width: 98%;
    min-height: 250px;
    margin: auto;
    direction: rtl;
    text-align: center;
}

.specialPopComp .MuiPaper-root {
    width: 100%;
    margin: 2px;
    max-height: unset;
}

.specialPopComp.InbgLGray .MuiPaper-root {
    background-color: var(--veryLightGray);
}

.specialPopComp .insideFrame {
    padding: 0 !important;
    line-height: 1;
}

.specialPopComp .insideFrame .details input {
    line-height: 35px;
}

.headAndBtnWrap {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.backSdLogin {
    width: 25px;
    height: 25px;
    top: 5px;
    border-radius: 50%;
    z-index: 801;
    float: right;
    position: relative;
    background-color: var(--mainYellow);
    margin-right: 10px;
}

.backSdLogin svg {
    width: 15px;
    height: 15px;
    top: 5px;
    transform: rotate(180deg);
    color: black;
    background-color: transparent;
    z-index: 801;
    position: relative;
}

.backS {
    width: 25px;
    height: 25px;
    top: 5px;
    border-radius: 50%;
    z-index: 801;
    float: right;
    position: relative;
    background-color: var(--lGray);
    right: 10px;
    top: 10px;
}

.backS svg {
    width: 15px;
    height: 15px;
    top: 5px;
    transform: rotate(180deg);
    color: black;
    background-color: transparent;
    z-index: 801;
    position: relative;
}

.OrderTypeSelectPop,
.OrderTblPop,
.approveMsgPopup {
    z-index: 9000 !important;
}

.OrderTblPop .MuiPaper-root {
    width: 80%;
    max-width: 500px !important;
    margin: 2px;
    height: 95%;
}

.landingPagepopUp .MuiPaper-root {
    width: 98%;
    max-width: 500px !important;
    margin: 2px;
    height: 100%;
    max-height: 97%;
}

.landingPagepopUp .MuiBackdrop-root {
    background-color: black;
}

.landingFullBG {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-size: cover;
    background-position: 0 0;
    z-index: 0;
}

.landingContent {
    position: relative;
}

.OrderTypeSelectPop .MuiPaper-root {
    width: 80%;
    max-width: 500px !important;
    margin: 2px;
    max-height: 95%;
}

.approveMsgPopup .imgblok {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 50%;
    width: 100%;
    margin: 0 auto;
}

.popFullComp .insideFrame {
    padding: 0 !important;
    line-height: 1;
}

.popFullComp .insideFrame .details input {
    line-height: 35px;
}

.approveMsgPopup .theTitlePop {
    font-size: var(--anakMeodMeod) !important;
}

.approveMsgPopup .textSizeDiag {}

.yelowBtn {
    background-color: var(--mainYellow) !important;
}

.blueBtn {
    background-color: rgb(0 81 255 / 83%) !important;
}

.whiteText {
    color: white !important;
}

.approveMsgPopup .btnPopRegular {
    color: #fff;
    background-color: #42d390;
    width: 50%;
    margin: 0 auto;
    line-height: 2 !important;
    border-radius: 20px;
    font-size: var(--bigFont) !important;
}

.approveMsgPopup .theTitlePop {
    color: #42d390;
}

.UnapproveMsgPopup .btnPopRegular {
    color: #fff;
    background-color: red;
}

.UnapproveMsgPopup .theTitlePop {
    color: red;
}

.orderOneClose .btnPopRegular {
    width: 80%;
    /* position: relative; */
}

.orderOneClose .btnPopRegularSec,
.DinnerBtn.btnPopRegularSec {
    background: none;
    font-size: var(--MyFont);
    text-decoration: underline;
}

.orderOneClose .css-hlj6pa-MuiDialogActions-root {
    padding: unset;
}

.textSizeDiag .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    width: 100%;
    /* min-height: 250px; */
    /* margin: auto; */
    direction: rtl;
    text-align: center;
}

button {
    font: var(--myFont) !important;
    font-weight: 200 !important;
}

.oops .MuiPaper-root {
    width: 100%;
    max-width: 400px;
}

.oopsTxt {
    line-height: 0.9rem !important;
    font-weight: normal !important;
}

.usrDet {
    overflow: hidden;
    margin-bottom: 5px;
}

.usrDet input,
.usrDet textarea,
.formControlDrp {
    width: calc(100% - 20px);
    margin: 0 auto;
    line-height: 45px;
    border-radius: 10px;
    font-weight: bold;
    border: none;
    padding-right: 10px;
    overflow: hidden;
    background-color: var(--mainYellowOpace);
    color: black;
}

.addAddressBlock .usrDet input,
.addAddressBlock .usrDet textarea,
.addAddressBlock .formControlDrp {
    background-color: var(--mainTurqi);
}

.spanCity {
    float: right;
    width: 40px;
    line-height: 45px;
    margin-right: 5px;
    color: var(--GrayPlus);
}

select.connInput {
    background-color: var(--mainTurqi);
    width: calc(100% - 55px);
    float: right;
    height: 45px;
    border-radius: 5px;
    border-color: snow;
    padding: 5px;
}

.customMenuItem {
    background-color: var(--mainTurqi);
    height: 45px;
}

.usrDet input::placeholder,
.usrDetSmall input::placeholder {
    color: var(--GrayPlus);
}

.usrDet ::placeholder,
.usrDetSmall ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--GrayPlus);
    opacity: 1;
    /* Firefox */
}

.usrDet :-ms-input-placeholder,
.usrDetSmall :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--GrayPlus);
}

.usrDet ::-ms-input-placeholder,
.usrDetSmall ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--GrayPlus);
}

.usrDetSmall input {
    width: calc(25% - 18px);
    margin-right: 2px;
    margin-left: 2px;
    line-height: 45px;
    border-radius: 10px;
    font-weight: bold;
    border: none;
    /* padding-right: 5px; */
    overflow: hidden;
    background-color: var(--mainTurqi);
    color: black;
    float: right;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px var(--mainTurqi) inset !important;
    -webkit-box-shadow: 0 0 0 30px var(--mainTurqi) inset !important;
}

.usrDetChk {
    overflow: hidden;
}

.errorMe input {
    border: 2px solid red;
}

.errorMe span svg {
    color: red !important;
}

.errorLine p {
    color: red !important;
}

.readItLink {
    color: blue !important;
    text-decoration: underline;
    font-family: var(--MyFont) !important;
}

.usrDetChk span,
.usrDetChk a {
    float: right;
    line-height: 25px;
    padding: 0px 5px 0px 0px;
}

.ConDetailsBtn {
    height: 45px;
    background-color: var(--mainYellow);
    border-radius: 15px;
    position: relative;
    width: 95%;
    margin: 0 auto;
    text-align: center;
    line-height: 45px;
    border: none;
}

.unsubscribeBtn {
    margin-top: 20px;
    height: 45px;
    background-color: var(--specialRed);
    border-radius: 15px;
    position: relative;
    width: 95%;
    margin: 0 auto;
    text-align: center;
    line-height: 45px;
    border: none;
    color: white;
}

.fatInBtn {
    height: 45px;
    background-color: var(--mainTurqi);
    border-radius: 15px;
    position: relative;
    width: 80%;
    margin: 0 auto;
    text-align: center;
    line-height: 45px;
    border: none;
}

.splitSelector {
    display: table;
    float: left;
    margin-left: 5px;
    margin-top: 5px;
}

.splitSelector img {
    width: 20px;
    height: 20px;
    margin-left: 5px;
    opacity: 0.5;
    position: relative;
}

.halfPTurn {
    transform: rotate(180deg);
}

.fullShow {
    opacity: 1 !important;
}

.qurt2Turn {
    transform: rotate(90deg);
}

.qurt3Turn {
    transform: rotate(180deg);
}

.qurt4Turn {
    transform: rotate(270deg);
}

.quartSelect {
    /* width: 100%; */
    background-color: white;
    display: block;
    position: absolute;
    padding-bottom: 10px;
    padding-top: 10px;
    z-index: 99;
}

.firstDelTd {
    width: 10%;
}

.addressDelTd {
    width: 90%;
}

.addressAddBtn {
    height: 30px;
    /* background-color: var(--mainYellow); */
    border-radius: 15px;
    position: relative;
    width: 50%;
    padding-right: 10px;
    /* margin: 0 auto; */
    float: right;
    text-align: center;
    line-height: 30px;
    border: none;
}

.plusOnAddRes {
    position: absolute;
    right: 15px;
    top: 3px;
}

.addAddressBlock {
    margin-bottom: 20px;
}

.popDiagBlock {
    direction: rtl;
    margin: 0 auto;
}

.theDinersPlusMinus {
    width: calc(50% - 20px);
    margin-top: 0;
    margin-right: 10px;
}

.DinnersChkTable {
    margin: unset;
    width: 100%;
}

.DiChk_row {
    width: 100%;
    overflow: hidden;
    display: table-row;
}

.DiChk_cell {
    overflow: hidden;
    display: table-cell;
}

.DiChk_cell2 {
    overflow: hidden;
    display: table-cell;
}

.DinnersChkTable {
    text-align: right;
}

.DinnersChkBlock ul,
.DinnersChkBlock ul li {
    list-style-type: none;
    padding: 0;
    text-align: right;
}

.DinnersChkBlock .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    width: 100%;
    height: 95%;
    margin: 15px;
}

.DinnersChkBlock .MuiDialogTitle-root+.css-ypiqx9-MuiDialogContent-root {
    padding: 2% 2%;
}

.bigPopUp {
    direction: rtl;
}

.theTitlePop {
    font-weight: bold !important;
    font-size: 120% !important;
}

.theTitlePopSmall {
    font-weight: bold !important;
    font-size: 155% !important;
    line-height: 1.7rem !important;
}

.theTxtPop,
.theTxtPop span,
.theTxtPop p span,
.popDiagBlock,
.popDiagBlock p span,
.specialPopComp,
.specialPopComp span,
.specialPopComp div,
.specialPopComp a,
.specialPopComp p,
.specialPopComp p span {
    color: black;
    font-family: var(--MyFont) !important;
}

.btnPopRegular {
    /* position: absolute;  */
    margin: 0 auto;
    border-radius: 10px;
    padding: 5px;
    background-color: var(--mainTurqi);
    width: 100%;
    text-align: center;
    line-height: 15px;
    border: none;
    font-weight: bold;
    font-size: 15px;
    color: black;
    bottom: 15px;
    left: 23%;
}

.btnYellow {
    background-color: var(--mainTurqi);
}

.btnYellow1 {
    background-color: var(--mainYellow);
}

.btnRed {
    background-color: var(--specialRed);
    color: white;
}

.btnGreen {
    background-color: var(--specialGreen2);
    color: white;
}

.btnPoppadding15 {
    padding: 15px;
}

.lnkPop {
    text-align: center;
    margin: 0 auto;
    text-decoration: underline;
}

.okPOP {}

.css-o3d33y,
.css-bdhsul-MuiTypography-root-MuiDialogTitle-root,
.css-qfso29-MuiTypography-root-MuiDialogContentText-root {
    font-family: var(--SecondFont) !important;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    border-radius: 10px !important;
    padding: 10px !important;
}

.hideMeLeft {
    position: absolute !important;
    left: -100% !important;
    right: 100% !important;
    -webkit-transition: left 1s, right 1s;
    -moz-transition: left 1s, right 1s;
    -o-transition: left 1s, right 1s;
    transition: left 1s, right 1s;
}

.imgWaiting {
    position: fixed;
    left: 0;
    right: 0;
    top: 50;
    bottom: 0;
    z-index: 10000;
    overflow-y: none !important;
    height: 100%;
    background-color: white;
}

.outOfStock {
    margin-bottom: 10px;
    /* -webkit-filter: grayscale(60%);
    -moz-filter: grayscale(60%);
    -ms-filter: grayscale(60%);
    filter: grayscale(60%); */
}

.outOfStock:after {
    content: "אין במלאי";
    position: absolute;
    top: 35%;
    left: 10%;
    font-size: 10px;
    border-radius: 5px;
    padding: 5px;
    -webkit-transform: rotate3d(1, 1, 1, -20deg);
    transform: rotate3d(1, 1, 1, -20deg);
    background-color: #fec000 !important;
}

.outOfStock .addToOrderBtn,
.outOfStock .priceln {
    display: none !important;
}

.overLay {
    position: absolute;
    /* Sit on top of the page content */
    display: none;
    /* Hidden by default */
    width: 100%;
    /* Full width (cover the whole page) */
    height: 100%;
    /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    /* Black background with opacity */
    z-index: 2;
    /* Specify a stack order in case you're using a different order for other elements */
    cursor: pointer;
    /* Add a pointer on hover */
}

.imgWaitingSpecial {
    background-color: rgba(0, 0, 0, 0.7);
}

.imgWaiting img {
    width: 100%;
}

.imgOnPopUp {
    width: 98%;
    padding-top: 75%;
    background-position: center;
    background-size: cover;
}

.imgOnPopUp img {
    width: 100%;
}

.popBig .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
    /* margin: 32px; */
    position: relative;
    overflow-y: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    max-height: calc(100% - 64px);
    /* max-width: 600px; */
    width: 85%;
}

.orderLINE {
    width: 100%;
    overflow: hidden;
    padding-top: 2px;
    padding-bottom: 8px;
    line-height: 15px;
}

.orderLINE svg {
    width: 15px;
    height: auto;
    top: 3px;
    padding-left: 5px;
    position: relative;
}

.countTxtOrder {
    float: right;
    line-height: var(--huge);
}

.countTimOrder {
    float: left;
    padding: 5px;
}

.css-ahj2mt-MuiTypography-root {
    /* font-family: var(--MyFont) !important; */
    /* padding-top: 3px !important; */
}

.selectionChk {
    overflow: hidden;
}

.selectionChk .css-vqmohf-MuiButtonBase-root-MuiRadio-root {
    padding: 4px;
}

.sideBySide {
    width: 50%;
    float: right;
}

.selectionChk label {
    margin-right: 0px;
}

.selectionChk svg {
    color: var(--turqiText);
}

.css-v2ax3c-MuiButtonBase-root-MuiCheckbox-root {
    padding: 4px !important;
}

.css-v2ax3c-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
    color: auto;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate,
.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
    color: var(--turqiText) !important;
}

.css-v2ax3c-MuiButtonBase-root-MuiCheckbox-root.Mui-checked {
    color: var(--turqiText) !important;
}

.timeTxt .css-i4bv87-MuiSvgIcon-root {
    transform: matrix(-1, 0, 0, 1, 0, 0);
}

.SinglePageContent {
    padding-top: 70px;
    position: relative;
    width: 100%;
    text-align: right;
}

.SinglePage {
    min-height: 200px;
    margin: unset;
}

.ContactPage .singlPImg img {
    /* width: 50px; */
    object-fit: cover;
    margin: 0 auto;
    display: block;
}

.singlPImg {
    width: 80%;
    top: 10px;
    height: auto;
    position: relative;
    overflow: hidden;
    margin: 0 auto;
}

.singlPImg img {
    width: auto;
    object-fit: cover;
    max-height: 200px;
}

.stickeySideMsg {
    width: 75px;
    height: 75px;
    position: fixed;
    z-index: 6000;
}

.stiickeyOnlnBlk {
    width: 15%;
    height: 20%;
    max-width: 48px;
    max-height: 52px;
    position: absolute;
    z-index: 500;
}

.notGettinOrders {
    text-align: center;
    width: 90%;
    left: 5%;
    top: 5px;
    height: 15px;
    font-weight: bold;
    /* max-width: 48px;
    max-height: 52px; */
    position: absolute;
    z-index: 5000;
    background-color: var(--mainYellow);
}

.HighlightOffSticky {
    float: left;
    color: black;
    font-size: 15px !important;
    top: 5px;
    left: -2px;
    position: relative;
}

.popUpCloseBtn {
    font-size: 30px !important;
    top: 15px;
    right: 15px;
    position: relative;
}

.inBlue {
    color: var(--turqiText);
}

.inBlack {
    color: black;
}

.wrapUnsubscribePage {
    padding: 10px;
}

.underLineMe {
    text-decoration: underline;
    color: var(--turqiText);
}

@media not all and (min-resolution:.001dpcm) {
    @supports (-webkit-appearance: none) {
        :root {
            --smallFont: 90%;
            --mediumFont: 100%;
            --regularFont: 120%;
            --bigFont: 140%;
            --veryBigFont: 160%;
            --huge: 180%;
        }

        .SecondFont {
            font-family: var(--SecondFont);
            font-size: 210% !important;
        }

        .SecondFontRegular {
            font-family: var(--SecondFont);
        }
    }
}

.appslInkPop {
    font-family: var(--MyFont);
}